<template>
  <div class="auth">
    <div class="auth-card">
      <div class="auth-card__logo">
        <img
          src="@/assets/img/logoBSL-black.svg"
          alt="BSL"
        >
      </div>
      <template v-if="isSendMode">
        <h1 class="auth-card__title mt-6">Забыли пароль?</h1>
        <div class="auth-card__subtitle mt-6">
          Введите ваш email или логин, и мы вышлем вам на почту ссылку для сброса пароля
        </div>
        <v-form
          ref="form"
          v-model="isValid"
          lazy-validation
          @submit.prevent="submit"
          class="mt-6"
        >
          <v-text-field
            v-model="form.email"
            :rules="emailRules"
            label="E-mail"
            class="rounded-lg"
            required
            outlined
            rounded
          />

          <v-btn
            color="black"
            dark
            x-large
            class="modal__btn"
            type="submit"
          >Отправить</v-btn>

          <div class="text-center mt-6">
            <router-link :to="{name: 'Login'}">
              <v-icon
                size="small"
                color="#1976d2"
              >mdi-chevron-left</v-icon>
              Назад к Авторизации
            </router-link>
          </div>
        </v-form>
      </template>

      <template v-else>
        <h1 class="auth-card__title mt-6">Ссылка для сброса пароля успешно отправлена</h1>

        <div class="auth-card__subtitle mt-6">
          Пожалуйста, проверьте свою электронную почту
          <span class="text-decoration-underline">{{form.email}}</span>
        </div>

        <v-btn
          color="black"
          dark
          x-large
          class="modal__btn mt-8"
          :to="{name: 'Login'}"
        >
          <v-icon
            size="big"
            color="#ffffff"
            class="mr-1"
          >mdi-chevron-left</v-icon>
          <span style="font-weight: 700; color: #ffffff">Назад к Авторизации</span>
        </v-btn>
      </template>

    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'ForgotPassword',
  data: () => ({
    isSendMode: true,
    isValid: true,
    form: {
      email: '',
    },
  }),

  computed: {
    ...mapGetters({
      loading: 'auth/loading',
    }),
  },

  methods: {
    ...mapActions({
      issuePasswordReset: 'auth/issuePasswordReset',
    }),
    async submit() {
      if (this.loading) return;
      await this.$refs.form.validate();
      if (!this.isValid) return;
      this.issuePasswordReset(this.form.email).then(() => {
        this.isSendMode = false;
      });
    },
  },
};
</script>
